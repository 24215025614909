<template>
  <div class="work__wrap">
    <div class="rowOne plr16">
      <div class="row2">
        <div class="row2-item">
          <div class="p7">
            今日订单 <span style="color: #999">（实时统计数据）</span>
          </div>
          <div
            class="p8"
            style="
              font-size: 24px;
              color: #262626;
              font-weight: bold;
              margin: 10px 0 5px;
            "
          >
            {{ today_order.amount }}
          </div>
          <div class="p3">今日订单销售额 (元)</div>
          <div
            class="p3"
            :class="
              Math.sign(today_order.sub_amount) >= 0 ? 'upCol' : 'downCol'
            "
          >
            较昨日
            {{ Math.sign(Number(today_order.sub_amount)) >= 0 ? "+" : " " }}
            {{ today_order.sub_amount }}
            <span
              :class="
                Math.sign(today_order.sub_amount) >= 0 ? 'up-icon' : 'down-icon'
              "
            ></span>
          </div>
        </div>
        <div class="row1-item">
          <div style="margin-top: 24px">
            <span class="num2" style="font-weight: bold">{{
              today_order.num
            }}</span>
          </div>
          <div class="row2-title">今日总订单 (笔)</div>
          <div
            class="p3"
            :class="Math.sign(today_order.sub_num) >= 0 ? 'upCol' : 'downCol'"
          >
            较昨日 {{ Math.sign(Number(today_order.sub_num)) >= 0 ? "+" : " " }}
            {{ today_order.sub_num }}
            <span
              :class="
                Math.sign(today_order.sub_num) >= 0 ? 'up-icon' : 'down-icon'
              "
            ></span>
          </div>
        </div>
        <!-- 若该配送单位没有启用分拣功能，则没有待分拣订单没有分拣完成率 -->
        <div class="row1-item" v-if="open_pick == 1">
          <div>
            <span class="num2">{{ today_order.await_count }}</span>
          </div>
          <div class="row2-title">待分拣订单 (笔)</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="num2">{{ today_order.dis_count }}</span>
          </div>
          <div class="row2-title">待配送订单 (笔)</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="num2">{{ today_order.distr_status }}</span>
          </div>
          <div class="row2-title">待送达订单 (笔)</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="num2">{{ today_order.complete_count }}</span>
          </div>
          <div class="row2-title">已完成订单 (笔)</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="num2">{{ today_order.cancel_count }}</span>
          </div>
          <div class="row2-title">已取消订单 (笔)</div>
        </div>
      </div>
    </div>

    <div class="rowTwo">
      <el-row style="padding:20px 0;">
        <el-col :span="12" style="border-right:solid 1px #eee;">
          <span class="titleText" style="position: absolute;top: 20px;">今日下单食堂</span>
          <div style="height: 360px">
            <Echart :options="options5" height="100%" width="100%"></Echart>
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="flex"
            style="
              justify-content: space-between;
              padding-left: 20px;
            "
          >
            <span class="titleText">订单流转情况</span>
            <span style="font-size: 13px; color: #999999"
              >( 确认客户订单 → 审核采购单 → 分拣商品 → 配送商品 → 订单完成/售后
              )</span
            >
          </div>
          <span style=" margin-left: 20px;">配送日期：</span>
          <el-date-picker
            v-model="params.currDate"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="mini"
            style="width: 150px; margin-top: 20px"
            :clearable="false"
            placeholder="选择日期"
            @change="getOrder"
          >
          </el-date-picker>
          <div
            style="
              width: 100%;
              height: 260px;
              display: flex;
              justify-content: space-between;
            "
          >
            <!-- 若该配送单位没有启用分拣功能，则没有待分拣订单没有分拣完成率 -->
            <Echart :options="options6" height="100%" width="100%"></Echart>
            <Echart :options="options7" height="100%" width="100%"></Echart>
            <Echart
              :options="options8"
              height="100%"
              width="100%"
              v-if="open_pick == 1"
            ></Echart>
            <Echart :options="options9" height="100%" width="100%"></Echart>
            <Echart :options="options10" height="100%" width="100%"></Echart>
          </div>
          <div
            style="
              margin-left: 10px;
              text-align: center;
              text-align: center;
              width: 100%;
              display: block;
            "
          >
            <span class="init init1">订单确认</span>
            <span class="init init2">配送完成</span>
            <span class="init init3" v-if="open_pick == 1">分拣完成</span>
            <span class="init init4">配送完成</span>
            <span class="init init5">退货</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <div style="padding:20px;">
      <div class="row1 bg">
        <div class="row1-item">
          <div class="p1">经营分析:<span style="margin-left:10px;color:#333;">配送日期</span></div>
          <div class="p2">
            <el-date-picker
              v-model="params.selCountTime"
              type="daterange"
              size="mini"
              style="width: 225px"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="selpickerOptions"
              @change="changeTime"
            >
            </el-date-picker>
          </div>
          <div class="p3">（该统计不包含取消的订单和采购单数据）</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="p4">{{ stat.order_num }}</span>
            <span class="p5">笔</span>
          </div>
          <div class="row1-title">有效订单数</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="p4">{{ formatNumber(stat.order_money) }}</span>
            <span class="p5">
              {{ Number(stat.order_money) > 10000 ? "万元" : "元" }}</span
            >
          </div>
          <div class="row1-title">总销售额</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="p4">{{ stat.purchase_num }}</span>
            <span class="p5">笔</span>
          </div>
          <div class="row1-title">总采购单</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="p4">{{ stat.freight }}</span>
            <span class="p5">{{ Number(stat.freight) > 10000 ? "万元" : "元" }}</span>
          </div>
          <div class="row1-title">配送运费</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="p4">{{ formatNumber(stat.purchase_amount) }}</span>
            <span class="p5">
              {{ Number(stat.purchase_amount) > 10000 ? "万元" : "元" }}</span
            >
          </div>
          <div class="row1-title">总采购金额</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="p4">{{ formatNumber(stat.profit) }}</span>
            <span class="p5">
              {{ Number(stat.profit) > 10000 ? "万元" : "元" }}</span
            >
          </div>
          <div class="row1-title">总毛利</div>
        </div>
        <div class="row1-item">
          <div>
            <span class="p4">{{ stat.profit_rate }}</span>
            <span class="p5">%</span>
          </div>
          <div class="row1-title">毛利率</div>
        </div>
      </div>
    </div>

    <div class="rowThree">
      <el-row style="padding:20px 0;">
        <el-col :span="24">
          <div
            class="flex"
            style="
              justify-content: space-between;
              align-items: center;
              position: absolute;
              display: block;
            "
          >
            <span class="titleText">商品类别销售情况</span>
          </div>
          <div style="height: 340px">
            <Echart :options="options4" height="100%" width="100%"></Echart>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="rowThree" style="padding-bottom:10px;">
      <el-row  style="padding:20px 0;">
        <el-col :span="12" style="position: relative;border-right:solid 1px #eee;">
          <div
            class="flex"
            style="
              justify-content: space-between;
              align-items: center;
              padding-right:20px;
            "
          >
            <span class="titleText">食堂订单统计</span>
            <el-link size="mini" @click="$router.push('/client/bill')">查看全部<i class="el-icon-arrow-right"></i></el-link>
          </div>
          <div style="height: 550px;padding-right:6px;margin-top:20px;">
            <el-table
              :data="storeTableData"
              stripe
              :header-cell-style="{ background: '#E7F7F5' }"
              :row-style="{ height: '0' }"
              max-height="550"
            >
              <el-table-column label="序号" width="50" type="index"></el-table-column>
              <el-table-column
                prop="store_name"
                label="食堂名称"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                prop="total_num"
                label="订单笔数"
                width="100px"
                sortable
                :sort-method="sortBySalesCount1"
              >
              </el-table-column>
              <el-table-column
                prop="total_amount"
                label="订单金额"
                sortable
                :sort-method="sortBySalesAmount1"
              >
                <template slot-scope="{ row }">
                  <span style="color: #fb8c00">￥{{ row.total_amount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_purchase_price"
                label="出库金额"
                sortable
                :sort-method="sortBySalesPrice1"
              >
                <template slot-scope="{ row }">
                  <span>￥{{ row.total_purchase_price }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="profit"
                label="毛利"
                sortable
                :sort-method="sortBySalesProfit1"
              >
                <template slot-scope="{ row }">
                  <span>￥{{ row.profit }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="profit_rate"
                label="毛利率"
                header-align="right"
                align="right"
                sortable
                :sort-method="sortBySalesRate1"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.profit_rate }}%</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="12" class="elModel" style="position: relative">
          <div
            class="flex"
            style="
              justify-content: space-between;
              align-items: center;
              padding-left:20px;
            "
          >
            <span class="titleText">商品销售排行（前100）</span>
            <el-link size="mini" @click="$router.push('/finance/goodsCount')">查看全部<i class="el-icon-arrow-right"></i></el-link>
          </div>
          <div style="height: 300px;margin-top:20px;margin-left:20px;margin-right:-20px;">
            <el-table 
              :data="tableData" 
              stripe
              style="width:100%" 
              :header-cell-style="{ background: '#E7F7F5' }"
              max-height="550"
              >
              <el-table-column label="排名" width="50" type="index">
                <template slot-scope="{$index}">
                  <span :class="$index < 3 ? 'comely' : 'fade'">{{$index+1}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="good_name"
                label="商品名称">
              </el-table-column>
              <el-table-column 
                prop="total_num" 
                label="销量" 
                width="100px"
                sortable
                :sort-method="sortBySalesCount">
              </el-table-column>
              <el-table-column 
                prop="total_amount" 
                label="销售额" 
                header-align="right" 
                align="right" 
                sortable
                :sort-method="sortBySalesAmount">
                <template slot-scope="{ row }">
                  <span style="color: #fb8c00">￥{{ row.total_amount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_purchase_price"
                label="已采金额"
                sortable
                :sort-method="sortBySalesPrice"
              >
                <template slot-scope="{ row }">
                  <span>￥{{ row.total_purchase_price }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="profit"
                label="毛利"
                sortable
                :sort-method="sortBySalesProfit"
              >
                <template slot-scope="{ row }">
                  <span>￥{{ row.profit }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="profit_rate"
                label="毛利率"
                header-align="right"
                align="right"
                sortable
                :sort-method="sortBySalesRate"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.profit_rate }}%</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          
        </el-col>
      </el-row>
    </div>

    <div class="rowThree">
      <el-row style="padding:20px 0;">
        <el-col :span="24" class="rowTwo-left" style="border-right:solid 1px #eee;">
          <div class="flex"  style="position: absolute;top: 20px;z-index:999">
            <div class="status">
              <span
                v-for="(v, i) in [
                  { type: 1, name: '在架商品' },
                  { type: 2, name: '下架商品' },
                ]"
                :key="i"
                @click="handleStatus(v, i)"
                :class="i == index ? 'active' : ''"
                >{{ v.name }}</span
              >
            </div>
            <div class="num" style="margin-left:20px;">
              商品总数：<span style="color: #70c1ff">{{
                goods.goods_count
              }}</span>
              ; 规格总数：<span style="color: #52e27a">{{
                goods.spec_count
              }}</span>
            </div>
          </div>
          <div style="width: 100%; height: 340px">
            <Echart :options="options1" height="100%" width="100%"></Echart>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import Echart from "@/components/echart/index.vue";
import * as $echarts from "echarts";
import { mixinTable } from "@/mixins/table.js";
import { getStorage } from "@/storage";
import VueSeamlessScroll from "vue-seamless-scroll";
var current = 0;
export default {
  name: "",
  components: {
    Echart,
    VueSeamlessScroll
  },
  mixins: [mixinTable],
  data() {
    return {
      classOption: {
        step: 3, // 滚动速度
        limitMoveNum: 1, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1,// 0向下，1向上，2向左，3向右
        singleHeight: 48, // 滑动高度
        waitTime: 2000, //停顿时间
      },
      selpickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.isRestart = false;
          this.pickerMinDate = minDate.getTime();
          if (maxDate) {
            this.pickerMinDate = "";
          }
        },
        disabledDate: (time) => {
          //查询时间跨度为91天
          if (this.curveTime && this.isRestart) {
            return; // 存在选中的日期且没有重新选择日期 不做禁用处理
          }
          if (this.pickerMinDate !== "") {
            const one = 91 * 24 * 3600 * 1000;
            const minTime = this.pickerMinDate - one;
            const maxTime = this.pickerMinDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        },
      },
      open_pick: getStorage("open_pick") || 1, // 是否开启分拣功能：1-开启；2-关闭
      defaultStartDate: new Date(new Date().getFullYear(), 0, 1),
      index: 0,
      // 时间查询条件
      params: {
        selCountTime: [], // 统计总数日期查询条件
        status: 1, //商品状态 1:在架 2:下架
        currDate: '', // 订单流转情况查询时间默认当天
      },
      stat: {
        order_money: 0, //总销售额
        order_num: 0, //总订单数
        purchase_amount: 0, //总采购金额
        purchase_num: 0, //总采购笔数
        profit: 0, //总利润
        freight:0, //总运费
        profit_rate: 0,//毛利率

      },
      today_order: {
        amount: 0, //今日销售金额
        num: 0, //今日订单笔数
        await_count: 0, //今日待分拣
        dis_count: 0, //今日待配送
        distr_status: 0, //今日待送达
        complete_count: 0, //今日已完成
        cancel_count: 0, //今日已取消
        sub_amount: 0, //增加订单金额
        sub_num: 0, //增加订单笔数
      },
      goods: {
        goods_count: 0, //商品数量
        spec_count: 0, //规格数量
        line: [],
      },
      order_line: [], //今日食堂下单情况

      tableData: [], // 商品销售排行（前50）
      storeTableData: [], // 食堂订单统计

      options1: {
        legend: {
          show: true,
          top: "10px",
          right: '1%',
          itemWidth: 15,
          itemHeight: 10,
          textStyle: {
            color: "#262626",
            fontSize: 12,
            padding: [0, 8, 0, 8],
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
            },
          },
        },
       
        grid: {
          left: "1%",
          right: "4%",
          top: "20%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#595959",
                fontSize: 12,
                fontWeight: 400,
              },
              interval: 0,
              rotate: -50, //横坐标文字倾斜
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 500,
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#D2D2D2",
                fontSize: 12,
                fontWeight: 400,
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
        ],
        series: [
          {
            name: "商品数量",
            type: "bar",
            data: [],
            barWidth: 12,
            itemStyle: {
              color: new $echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#CBE9FF",
                },
              ]),
              emphasis: {
                color: "#70C1FF", // 选中时的颜色
              },
            },
          },
          {
            name: "规格数量",
            type: "bar",
            data: [],
            barWidth: 12,
            barGap: "40%",
            barCategoryGap: "30%",
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#C1F5CF",
                  },
                ]),
              },
              emphasis: {
                color: "#52E27A", // 选中时的颜色
              },
            },
          },
        ],
      },
      options4: {
        legend: {
          data: ["销售额", "采购额", "营收占比",],
          textStyle: {
            align: "right",
          },
          top: "0%",
          right: "center",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(102, 102, 102, 1)",
          textStyle: {
            color: "rgba(212, 232, 254, 1)",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            console.log(params);
            let res = "";
            return (res =
              params[0].name +
              "<br/>" +
              params[0].seriesName +
              " : " +
              params[0].value +
              "万元<br/>" +
              params[1].seriesName +
              " : " +
              params[1].value +
              "万元<br/>" +
               params[2].seriesName +
              " : " +
              params[2].value +
              "%");
          },
        },
        grid: {
          left: "1%",
          right: "3%",
          top: "20%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#000",
                fontSize: 12,
                fontWeight: 400,
              },
              interval: 0,
              rotate: -50, //横坐标文字倾斜
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
        ],
        yAxis: [
          {
            name: "销售额/万元",
            splitLine: { show: false },
            position: "left",
            nameTextStyle: {
              color: "#FF7E00",
            },
            type: "value",
            min: 0,
            // max: 80,
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#7F7F7F",
                fontSize: 12,
                fontWeight: 400,
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
          {
            name: "营收占比/%",
            nameTextStyle: {
              color: "#46C8FF",
            },
            type: "value",
            boundaryGap: false,
            position: "right",
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#7F7F7F",
                fontSize: 12,
                fontWeight: 400,
              },
            },
            splitLine: {
              show: false, // 是否显示水平线
              lineStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
        ],
        series: [
          {
            name: "销售额",
            type: "bar",
            data: [],
            // data: [20, 20, 50, 80, 70, 10, 30],
            barWidth: 20,
            itemStyle: {
              color: new $echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#FFEFDE", // 柱子默认颜色
                },
              ]),
              emphasis: {
                color: "#FF7E00", // 柱子选中时的颜色
              },
            },
          },
          {
            name: "采购额",
            type: "bar",
            data: [],
            // data: [20, 20, 50, 80, 70, 10, 30],
            barWidth: 20,
            itemStyle: {
              color: new $echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#7fffdd", // 柱子默认颜色
                },
              ]),
              emphasis: {
                color: "#15daa5", // 柱子选中时的颜色
              },
            },
          },
          {
            name: "营收占比",
            type: "line",
            smooth: false, //是否平滑
            data: [],
            // data: [10256, 58283, 53252, 112344, 12069, 92961, 62735],
            barWidth: 28,
            barGap: "40%",
            symbolSize: 6, // 标记的大小
            symbol: "circle", // 实心圆
            barCategoryGap: "30%",
            lineStyle: {
              type: "solid",
              width: 2,
            },
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#46C8FF", // 折线默认时的颜色
                  },
                ]),
              },
              emphasis: {
                borderColor: "#46C8FF", // 选择时标记的边框颜色
                borderWidth: 2, // 选择时标记的边框
                color: "#fff", // 选中时标记的颜色
              },
            },
          },
        ],
      },
      options5: {
        legend: {
          data: ["商品数量", "订单金额"],
          textStyle: {
            align: "right",
          },
          top: "0%",
          right: "center",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(102, 102, 102, 1)",
          textStyle: {
            color: "rgba(212, 232, 254, 1)",
          },
          axisPointer: {
            type: "shadow",
            // label: {
            //   show: true,
            //   fontSize: 12,
            //   borderColor: "#ff6600",
            //   shadowOffsetY: 0,
            //   color: "#262626",
            // },
          },
          formatter: function (params) {
            console.log(params);
            let res = "";
            return (res =
              params[0].name +
              "<br/>" +
              params[0].seriesName +
              " : " +
              params[0].value +
              "件<br/>" +
              params[1].seriesName +
              " : " +
              params[1].value +
              "元");
          },
        },
        grid: {
          left: "1%",
          right: "4%",
          top: "25%",
          bottom: "1%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            // data: [],
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#000",
                fontSize: 12,
                fontWeight: 400,
              },
              interval: 0,
              rotate: -50, //横坐标文字倾斜
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
        ],
        yAxis: [
          {
            name: "下单商品",
            splitLine: { show: false },
            position: "left",
            nameTextStyle: {
              color: "#63D15B",
            },
            type: "value",
            min: 0,
            // max: 80,
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#7F7F7F",
                fontSize: 12,
                fontWeight: 400,
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
          {
            name: "订单金额(元)",
            nameTextStyle: {
              color: "#FF7E00",
            },
            type: "value",
            boundaryGap: false,
            position: "right",
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#7F7F7F",
                fontSize: 12,
                fontWeight: 400,
              },
            },
            splitLine: {
              show: false, // 是否显示水平线
              lineStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D2D2D2",
              },
            },
          },
        ],
        series: [
          {
            name: "商品数量",
            type: "bar",
            // data: [],
            data: [20, 20, 50, 80, 70, 10, 30],
            barWidth: 30,
            itemStyle: {
              color: new $echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#E0F6DE", // 柱子默认颜色
                },
              ]),
              emphasis: {
                color: "#63D15B", // 柱子选中时的颜色
              },
            },
          },
          {
            name: "订单金额",
            type: "line",
            smooth: false, //是否平滑
            // data: [],
            data: [10256, 58283, 53252, 112344, 12069, 92961, 62735],
            barWidth: 10,
            barGap: "40%",
            symbolSize: 6, // 标记的大小
            symbol: "circle", // 实心圆
            barCategoryGap: "30%",
            lineStyle: {
              type: "solid",
              width: 2,
            },
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#FF7E00", // 折线默认时的颜色
                  },
                ]),
              },
              emphasis: {
                borderColor: "#FF7E00", // 选择时标记的边框颜色
                borderWidth: 2, // 选择时标记的边框
                color: "#fff", // 选中时标记的颜色
              },
            },
          },
        ],
      },
      options6: {
        title: [
          {
            text: "订单确认率",
            left: "50%",
            top: "75%",
            textAlign: "center",
            textStyle: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "pie",
            clockWise: false, // 外圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [0],
            itemStyle: {
              color: '#eee'
            },
            label: {
              show: false,
            },
          },
          {
            type: "pie",
            clockWise: false, // 内圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [
              {
                value: current,
                label: {
                  normal: {
                    formatter: `{label|${current}}%`,
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#FFAD4E",
                      rich: {
                        label: {
                          fontSize: "20",
                          fontWeight: "bold",
                          color: "#FFAD4E",
                        },
                      },
                    },
                  },
                },
                itemStyle: {
                  borderCap: "round",
                  normal: {
                    color: "#FFAD4E", // 占比圈颜色
                    borderRadius: ["50%", "50%"],
                  },
                },
              },
              {
                value: 100 - current,
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderCap: "round",
                  },
                },
              },
            ],
          },
        ],
      },
      options7: {
        title: [
          {
            text: "采购审核率",
            left: "50%",
            top: "75%",
            textAlign: "center",
            textStyle: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "pie",
            clockWise: false, // 外圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [100],
            itemStyle: {
              color: '#eee'
            },
            label: {
              show: false,
            },
          },
          {
            type: "pie",
            clockWise: false, // 内圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [
              {
                value: current,
                label: {
                  normal: {
                    formatter: `{label|${current}}%`,
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#58ABFF",
                      rich: {
                        label: {
                          fontSize: "20",
                          fontWeight: "bold",
                          color: "#58ABFF",
                        },
                      },
                    },
                  },
                },
                itemStyle: {
                  borderCap: "round",
                  normal: {
                    color: "#58ABFF", // 占比圈颜色
                    borderRadius: ["50%", "50%"],
                  },
                },
              },
              {
                value: 100 - current,
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderCap: "round",
                  },
                },
              },
            ],
          },
        ],
      },
      options8: {
        title: [
          {
            text: "分拣完成率",
            left: "50%",
            top: "75%",
            textAlign: "center",
            textStyle: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "pie",
            clockWise: false, // 外圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [100],
            itemStyle: {
              color: '#eee'
            },
            label: {
              show: false,
            },
          },
          {
            type: "pie",
            clockWise: false, // 内圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [
              {
                value: current,
                label: {
                  normal: {
                    formatter: `{label|${current}}%`,
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#5BBC54",
                      rich: {
                        label: {
                          fontSize: "20",
                          fontWeight: "bold",
                          color: "#5BBC54",
                        },
                      },
                    },
                  },
                },
                itemStyle: {
                  borderCap: "round",
                  normal: {
                    color: "#5BBC54", // 占比圈颜色
                    borderRadius: ["50%", "50%"],
                  },
                },
              },
              {
                value: 100 - current,
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderCap: "round",
                  },
                },
              },
            ],
          },
        ],
      },
      options9: {
        title: [
          {
            text: "配送完成率",
            left: "50%",
            top: "75%",
            textAlign: "center",
            textStyle: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "pie",
            clockWise: false, // 外圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [100],
            itemStyle: {
              color: '#eee'
            },
            label: {
              show: false,
            },
          },
          {
            type: "pie",
            clockWise: false, // 内圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [
              {
                value: current,
                label: {
                  normal: {
                    formatter: `{label|${current}}%`,
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#15DAA5",
                      rich: {
                        label: {
                          fontSize: "20",
                          fontWeight: "bold",
                          color: "#15DAA5",
                        },
                      },
                    },
                  },
                },
                itemStyle: {
                  borderCap: "round",
                  normal: {
                    color: "#15DAA5", // 占比圈颜色
                    borderRadius: ["50%", "50%"],
                  },
                },
              },
              {
                value: 100 - current,
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderCap: "round",
                  },
                },
              },
            ],
          },
        ],
      },
      options10: {
        title: [
          {
            text: "退货率",
            left: "50%",
            top: "75%",
            textAlign: "center",
            textStyle: {
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "pie",
            clockWise: false, // 外圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [100],
            itemStyle: {
              color: '#eee'
            },
            label: {
              show: false,
            },
          },
          {
            type: "pie",
            clockWise: false, // 内圈按顺时针方向
            radius: ["60%", "80%"],
            center: ["50%", "40%"],
            data: [
              {
                value: current,
                label: {
                  normal: {
                    formatter: `{label|${current}}%`,
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#F85951",
                      rich: {
                        label: {
                          fontSize: "20",
                          fontWeight: "bold",
                          color: "#F85951",
                        },
                      },
                    },
                  },
                },
                itemStyle: {
                  borderCap: "round",
                  normal: {
                    color: "#F85951", // 占比圈颜色
                    borderRadius: ["50%", "50%"],
                  },
                },
              },
              {
                value: 100 - current,
                itemStyle: {
                  normal: {
                    color: "transparent",
                    borderCap: "round",
                  },
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    // 默认一周
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7); // 往前推一个月
    // 统计总数默认为今年，从今年的1月1日开始统计
    // this.params.selCountTime[0] = new Date().getFullYear() + "-01" + "-01";
    // this.params.selCountTime[1] = this.$moment(end).format("YYYY-MM-DD");
    // 统计总数默认最近一周查询
    this.params.selCountTime[0] = this.$moment(start).format("YYYY-MM-DD");
    this.params.selCountTime[1] = this.$moment(end).format("YYYY-MM-DD");
    // 订单流转情况默认当天
    this.params.currDate = this.$moment(new Date()).format("YYYY-MM-DD");

    this.getBaseInfo();
    this.getCateSell(); // 商品类别销售情况
    this.getOrder(); // 订单流转情况
    this.getStoreOrder(); // 食堂订单统计
    this.getGoodsRank(); // 品销售排行
  },
  
  methods: {
    changeTime() {
      this.getBaseInfo();
      this.getCateSell(); // 商品类别销售情况
      this.getOrder(); // 订单流转情况
      this.getStoreOrder(); // 食堂订单统计
      this.getGoodsRank(); // 品销售排行
    },
    getBaseInfo() {
      let _params = {
        start: this.params.selCountTime[0],
        end: this.params.selCountTime[1],
        status: this.params.status, // 商品状态 1:在架 2:下架
      };
      this.$http.get("/admin/index/index", { params: _params }).then((res) => {
        if (res.code == 1) {
          this.stat = res.data.stat; // 总数据

          this.today_order = res.data.today_order; // 今日订单数据
          this.goods.goods_count = res.data.goods.goods_count; //商品数量
          this.goods.spec_count = res.data.goods.spec_count; //规格数量
          let _str = res.data.goods.line.map((a) => a.name); // 分类
          let _line1 = res.data.goods.line.map((b) => b.goods_count); // 商品数量
          let _line2 = res.data.goods.line.map((c) => c.spec_count); // 规格数量
          console.log(_str, "_str");
          this.options1.xAxis[0].data = _str;
          this.options1.series[0].data = _line1;
          this.options1.series[1].data = _line2;

          this.order_line = res.data.order_line; //今日食堂下单情况

          this.options5.xAxis[0].data = res.data.order_line.map(
            (d) => d.store_name
          ); // 食堂名称
          this.options5.series[0].data = res.data.order_line.map(
            (d) => d.total_num
          ); // 数量
          this.options5.series[1].data = res.data.order_line.map(
            (d) => d.total_amount
          ); // 金额
        }
      });
    },
    //商品销售类别情况
    getCateSell() {
      let _start = this.params.selCountTime[0] || "";
      let _end = this.params.selCountTime[1] || "";
      this.$http
        .get("/admin/index/cateSell", { params: { start: _start, end: _end } })
        .then((res) => {
          if (res.code == 1) {
            let arr = res.data.map(item => {
              return {
                ...item,
                // amount:  (item.amount / 10000).toFixed(2), // 元转万元
                total_amount:  (item.total_amount / 10000).toFixed(2), // 销售额元转万元
                delivery_money: (item.delivery_money / 10000).toFixed(2) // 元转万元
              }
            })
            this.options4.xAxis[0].data = arr.map(
              (d) => d.name
            ); // 分类名称
            this.options4.series[0].data = arr.map(
              (d) => d.total_amount
            ); // 销售额
            this.options4.series[1].data = arr.map(
              (d) => d.delivery_money
            ); // 采购额
            this.options4.series[2].data = arr.map(
              (d) => d.rate
            ); // 占比
          }
        });
    },
    // 订单流转情况
    getOrder() {
      this.$http
        .get("/admin/index/order", {
          params: { date: this.params.currDate },
        })
        .then((res) => {
          if (res.code == 1) {
            // 订单确认率
            let confirmRate = Number(res.data.stat.confirm_rate);
            this.options6.series[1].data[0].value = confirmRate;
            this.options6.series[1].data[0].label.normal.formatter = `{label|${confirmRate}}%`;
            this.options6.series[1].data[1].value = 100 - confirmRate;
            // 采购审核率
            let checkRate = Number(res.data.stat.check_rate);
            this.options7.series[1].data[0].value = checkRate;
            this.options7.series[1].data[0].label.normal.formatter = `{label|${checkRate}}%`;
            this.options7.series[1].data[1].value = 100 - checkRate;
            // 分拣完成率
            let sorterRate = Number(res.data.stat.sorter_rate);
            this.options8.series[1].data[0].value = sorterRate;
            this.options8.series[1].data[0].label.normal.formatter = `{label|${sorterRate}}%`;
            this.options8.series[1].data[1].value = 100 - sorterRate;
            // 配送完成率
            let deliveryRate = Number(res.data.stat.delivery_rate);
            this.options9.series[1].data[0].value = deliveryRate;
            this.options9.series[1].data[0].label.normal.formatter = `{label|${deliveryRate}}%`;
            this.options9.series[1].data[1].value = 100 - deliveryRate;
            // 退货率
            let refundRate = Number(res.data.stat.refund_rate);
            this.options10.series[1].data[0].value = refundRate;
            this.options10.series[1].data[0].label.normal.formatter = `{label|${refundRate}}%`;
            this.options10.series[1].data[1].value = 100 - refundRate;
          }
        });
    },
    // 食堂订单统计
    getStoreOrder() {
      let _start = this.params.selCountTime[0] || "";
      let _end = this.params.selCountTime[1] || "";
      this.$http
        .get("/admin/index/storeOrder", {
          params: { start: _start, end: _end },
        })
        .then((res) => {
          if (res.code == 1) {
            this.storeTableData = res.data.list;
          }
        });
    },
    // 商品销售排行（前100）
    getGoodsRank() {
      let _start = this.params.selCountTime[0] || "";
      let _end = this.params.selCountTime[1] || "";
      this.$http
        .get("/admin/index/goodsRank", { params: { start: _start, end: _end } })
        .then((res) => {
          if (res.code == 1) {
            this.tableData = res.data;
          }
        });
    },
    // 商品切换在架、下架状态查询
    handleStatus(v, i) {
      this.index = i;
      this.params.status = v.type;
      this.getBaseInfo();
    },
    // 金额元转万元
    formatNumber(num) {
      num = Number(num);
      if (num == 0 || (num > 0 && num < 10000)) {
        return num;
      } else {
        return (num / 10000).toFixed(2);
      }
    },
    // 订单金额排序
    sortBySalesAmount1(a, b) {
      if ("ascending") {
        return Number(a.total_amount) - Number(b.total_amount);
      } else {
        return Number(b.total_amount) - Number(a.total_amount);
      }
    },
    // 订单笔数排序
    sortBySalesCount1(a, b) {
      if ("ascending") {
        return Number(a.total_num) - Number(b.total_num);
      } else {
        return Number(b.total_num) - Number(a.total_num);
      }
    },
    // 出库金额排序
    sortBySalesPrice1(a, b) {
      if("ascending") {
        return Number(a.total_purchase_price) - Number(b.total_purchase_price);
      } else {
        return Number(b.total_purchase_price) - Number(a.total_purchase_price);
      }
    },
    // 毛利排序
    sortBySalesProfit1(a, b) {
      if("ascending") {
        return Number(a.profit) - Number(b.profit);
      } else {
        return Number(b.profit) - Number(a.profit);
      }
    },
    // 毛利率排序
    sortBySalesRate1(a, b) {
      if("ascending") {
        return Number(a.profit_rate) - Number(b.profit_rate);
      } else {
        return Number(b.profit_rate) - Number(a.profit_rate);
      }
    },

    // 销售额排序
    sortBySalesAmount(a, b) {
      if ("ascending") {
        return Number(a.total_amount) - Number(b.total_amount);
      } else {
        return Number(b.total_amount) - Number(a.total_amount);
      }
    },
    // 销量排序
    sortBySalesCount(a, b) {
      if ("ascending") {
        return Number(a.total_num) - Number(b.total_num);
      } else {
        return Number(b.total_num) - Number(a.total_num);
      }
    },
    // 已采金额
    sortBySalesPrice(a, b) {
      if ("ascending") {
        return Number(a.total_purchase_price) - Number(b.total_purchase_price);
      } else {
        return Number(b.total_purchase_price) - Number(a.total_purchase_price);
      }
    },
    // 毛利排序
    sortBySalesProfit(a, b) {
      if ("ascending") {
        return Number(a.profit) - Number(b.profit);
      } else {
        return Number(b.profit) - Number(a.profit);
      }
    },
    // 毛利率排序
    sortBySalesRate(a, b) {
      if ("ascending") {
        return Number(a.profit_rate) - Number(b.profit_rate);
      } else {
        return Number(b.profit_rate) - Number(a.profit_rate); 
      }
    },
  },
};
</script>

<style scoped lang="scss">
.work__wrap {
  background: #fff;
  overflow-y: scroll;
}
.work-item {
  background: #fff;
  position: relative;
}
.flex {
  display: flex;
}
.j-between {
  justify-content: space-between;
}

.up-icon {
  width: 12px;
  height: 14px;
  display: inline-block;
  margin-left: 5px;
  background: url("~@/assets/img/nav/up.png");
}
.down-icon {
  width: 12px;
  height: 14px;
  display: inline-block;
  margin-left: 5px;
  background: url("~@/assets/img/nav/down.png");
}
.upCol {
  color: #f85972 !important;
}
.downCol {
  color: #16cc6e !important;
}
.plr16 {
  padding: 10px 16px;
}
.rowOne {
  padding: 12px 24px;
}
.row1 {
  padding: 16px;
  border-radius: 6px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row2 {
  padding: 20px 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bg {
  background: #fff3eb;
}
.p1 {
  font-size: 14px;
  font-weight: bold;
  color: #ff791f;
}
.p2 {
  margin: 12px 0 8px;
}
.p3 {
  font-size: 12px;
  color: #999999;
  margin-top: 5px;
}
.p4 {
  font-size: 24px;
  font-weight: bold;
}
.num2 {
  font-size: 24px;
}
.p5 {
  margin-left: 8px;
}
.row1-title {
  color: #ff791f;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
}
.row2-title {
  color: #898989;
  margin-top: 5px;
  font-size: 14px;
}
.rowTwo {
  margin: 0 24px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}
.rowThree {
  margin: 0 24px;
  border-bottom: solid 1px #eee;

  .rowTwo-left {
    padding-right: 24px;
    font-size: 14px;

    .status {
      height: 30px;
      line-height: 30px;
      background: #f1f1f1;
      padding: 5px;
      cursor: pointer;

      span {
        padding: 5px 10px;
      }
    }
    .num {
      display: flex;
      align-items: center;
    }

    .active {
      background: #fff;
      color: #2cb167;
      font-weight: bold;
    }
  }
}

::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #f8f8f8 !important;
}
::v-deep .el-table tr {
  background: #fff;
}

.work__wrap::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.work__wrap::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 3px;
}

.comely{
  border: solid 1px #2cb167;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #2cb167;
  display: block;
  font-weight: bold;
}
.fade{
  border: solid 1px #595959;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #595959;
  display: block;
}
::v-deep .el-date-editor .el-range__close-icon {
  display: none;
}
.titleText {
  font-size: 18px;
  font-weight: bold;
}
.init {
  font-size: 12px;
  margin-right: 15px;
  margin-top: 20px;
}
.init1::before {
  content: " ";
  width: 15px;
  height: 10px;
  background: #ffad4e;
  display: inline-block;
  margin-right: 5px;
}
.init2::before {
  content: " ";
  width: 15px;
  height: 10px;
  background: #58abff;
  display: inline-block;
  margin-right: 5px;
}
.init3::before {
  content: " ";
  width: 15px;
  height: 10px;
  background: #5bbc54;
  display: inline-block;
  margin-right: 5px;
}
.init4::before {
  content: " ";
  width: 15px;
  height: 10px;
  background: #15daa5;
  display: inline-block;
  margin-right: 5px;
}
.init5::before {
  content: " ";
  width: 15px;
  height: 10px;
  background: #f85951;
  display: inline-block;
  margin-right: 5px;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
}
.seamless-warp {
  max-height: 253px;
  overflow: hidden;
}
.min {
  margin-top:20px;
  margin-left: 20px;
  display: flex;
  width: 97%;
}
.top ::v-deep .el-table__body-wrapper {
  display: none;
}
.bottom ::v-deep .el-table__header-wrapper {
  display: none;
  width: 100%;
}
</style>